._3ENh_JtWRd9c-pzDgpP1Ip {
  margin-top: 20px;
  margin-bottom: 20px; }

._3uXkh_jo0nYwrIYoGXAirK {
  font-size: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
