._3yJ_jW7qDdp2FBvXTJDfnZ {
  display: flex;
  flex-direction: row;
  align-items: stretch; }

._2o00ZECBtqM_-oFs1u6HZI {
  display: flex;
  width: 305px;
  flex-direction: column; }
  ._2o00ZECBtqM_-oFs1u6HZI div:nth-child(n+2) {
    margin-top: 26px; }

._3wigruudrndIVqrPpgu7Cf {
  display: flex;
  margin-left: 15px;
  max-width: 90px;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end; }
  ._3wigruudrndIVqrPpgu7Cf div {
    margin-top: 10px; }
